import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../components/Layout';
import Hero from '../../components/Hero';

const Thanks = () => (
	<Layout>
		<Helmet>
			<title>Tack</title>
		</Helmet>
		<Hero />
		<section className="section">
			<div className="container">
				<div className="columns">
					<div className="column is-10 is-offset-1">
						<div className="content has-text-centered">
							<h1>Tack för ditt meddelande!</h1>
							<p>Vi återkommer till dig så snart vi kan!</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export default Thanks;
